import request from '@/utils/request'

// 获取分类列表
export function queryGoodsType () {
    return request({
        url: '/shd-operation/lrGoodsClassify/queryGoodsType',
        method: 'get'
    })
}

//获取门店列表
export function listOfPage () {
    return request({
        url: '/goods-service/store/listOfPage',
        method: 'get'
    })
}

// 删除
export function delGoodsFreshInfo (id) {
    return request({
        url: `/shd-operation/lrGoodsInfo/delGoodsFreshInfo?id=${id}`,
        method: 'delete'
    })
}

// 上下架验证 `/shd-operation/lrGoodsInfo/upAndDownGoods?id=${row.goodsId}&status=${status}`;
export function upAndDownGoods (data) {
    return request({
        url: `/shd-operation/lrGoodsInfo/upAndDownGoods?id=${data.goodsId}&status=${data.status}`,
        method: 'get'
    })
}

//数据初始化 /shd-operation/lrGoodsInfo/queryFJGoodsList
export function queryFJGoodsList (data) {
    return request({
        url: '/goods-service/goodsSupermarkert/page',
        method: 'post',
        data
    })
}
// 以上是旧版的接口------------------弃用，启用以下对接牵牛花的新接口

// 1 保存或者更新
export function saveOrUpdate (data) {
    return request({
        url: `/goods-service/goodsSupermarkert/saveOrUpdate`,
        method: 'post',
        data
    })
}
// 2 查询商品分页
export function goodsSupermarkertPage (data) {
    return request({
        url: `/goods-service/goodsSupermarkert/page`,
        method: 'post',
        data
    })
}
// 3 根据商品id删除商品
export function deleteByGoodId (id) {
    return request({
        url: `/goods-service/goodsSupermarkert/delete/${id}`,
        method: 'get'
    })
}
// 4 上下架商品
export function downOrUp (data) {
    return request({
        url: `/goods-service/goodsSupermarkert/downOrUp`,
        method: 'post',
        data
    })
}
// 5 根据商品id获取商品详情
export function getInfoById (id) {
    return request({
        url: `/goods-service/goodsSupermarkert/getInfoById/${id}`,
        method: 'get'
    })
}