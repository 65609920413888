import request from '@/utils/request'

// 获取丽日商品分类列表
export function getProcudtCateList (params) {
    return request({
        url: '/goods-service/goods/classify/queryGoodsType',
        method: 'get',
        params
    })
}

// 获取商超、百货商品分类树形列表
export function treeGoodsClassifyList (data) {
    return request({
        url: '/goods-service/goods/classify/tree/goodsClassifyList',
        method: 'post',
        data
    })
}

// 获取丽日商品分类列表不分页
export function getProcudtCateListAll (params) {
    return request({
        url: '/goods-service/goods/classify/queryGoodsTypeList',
        method: 'get',
        params
    })
}

//添加、编辑分类提交
export function subCateForm (data) {
    return request({
        url: '/goods-service/goods/classify/addGoodsType',
        method: 'post',
        data
    })
}

// 删除分类(支持批量删除)
export function deleteCate (data) {
    return request({
        url: '/goods-service/goods/classify/delGoodsType',
        method: 'delete',
        data
    })
}



